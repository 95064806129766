.EventLocation {
  .Text {
    font-size: 1rem !important;
  }

  .DirectionSignIcon {
    width: 24px;
  }

  .HeadsetsIcon, .MapLocationIcon {
    width: 24px;
  }
}