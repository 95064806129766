.Input {
    label {
        font-size: 0.8rem !important;
    }
}

.InputContainer {
    display: flex;
    align-items: center;
    height: 100%;
    border-radius: 8px;
    border: 1px solid #9e9e9e80;
    height: 100% !important;
    background-color: #ffffff;

    img:first-child {
        width: 16px;
        height: 16px;
    }

    img:last-child {
        width: 10px;
        height: 16px;
    }

    img:last-child {
        cursor: pointer;
    }

    input {
        font-family: 'Noto Sans';
        font-size: 0.8rem !important;
        font-weight: 600;
        margin: 0 !important;
        height: 100% !important;
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
        outline: none !important;
        border: none;

        height: 48px;
        color: #1b1b1b;
        &::placeholder {
            color: #1b1b1b;
        }
    }

    input:focus,
    input:focus-visible {
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
    }
}

.Focused {
    border: 2px solid #00366b;
}

.InputInvalid {
    border: 1px solid #d80606 !important;

    label {
        color: #d80606 !important;
    }
}

.InvalidLabel {
    color: #d80606 !important;
}

.TooltipContent {
    font-family: 'Noto Sans';
    font-size: 0.8rem;
    color: #ffffff !important;

    span:last-child {
        text-decoration: underline;
        cursor: pointer;
    }
}
