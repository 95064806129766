#tooltip-container {
}

#tooltip-container > .tooltip-inner {
    background-color: #797979;
    margin-top: 1px;
    border-radius: 4px;
    opacity: 1;
    max-width: 300px;
}

#tooltip-container.show {
    opacity: 1;
}

#tooltip-container > .tooltip-arrow {
    border-bottom-color: #797979 !important;
    left: -2.5rem !important;
}

#tooltip-container > .tooltip-arrow::before {
    border-bottom-color: #797979 !important;
}
