.EventDetailsModal {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 900;
    width: 100vw;
    height: 100vh;
    background-color: rgba(38,38,38,0.5);

    .Modal {
        height: 100%;
        width: 837px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
        z-index: 901;

        .Header {
        }

        .Body {
            overflow-x: unset;
            overflow-y: auto;

            @media screen and (min-width: 576px) {
                padding-left: 69px;
                padding-right: 69px;
            }

            @media screen and (max-width: 576px) {
                padding-left: 16px;
                padding-right: 16px;
            }
        }

        .Footer {
            height: 95px;
        }
    }
}
