.RadioButton {
    display: flex;
    justify-content: flex-start;
    padding: 10px 24px 10px 10px;
    width: -moz-fit-content;
    width: fit-content;
    background: #fff;
    border: 2px solid rgba(158, 158, 158, 0.5);
    border-radius: 50px;
    margin-left: 10px;
    cursor: pointer;

    span {
        padding-left: 10px;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1rem;
        font-weight: 600;
        color: #1b1b1b;
    }

    input[type='radio'] {
        visibility: hidden;
    }

    input[type='radio']:checked ~ div {
        // background-color: #12509f;
    }

    input[type='radio']:checked ~ div .bi-check2::before {
        color: #ffffff;
        font-weight: bold !important;
    }

    input[type='radio']:checked ~ div .bi-check2 {
        display: block;
    }

    input[type='radio'] ~ div .bi-check2 {
        display: none;
    }
}

.CheckmarkBox {
    padding: 0 !important;
    width: 20px;
    height: 20px;
    background-color: #fff;
    color: #ffffff;
    border: 2px solid #959595;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    cursor: pointer;

    .dot {
        background-color: #12509f;
        width: 12px;
        height: 12px;
        border-radius: 50%;
    }
}

.BorderPrimaryBlue {
    border: 2px solid #12509f;
    background: #eef3f8;
}
