.EventListItem {
    box-shadow: none !important;
    border: 1px #cac4d0 solid !important;
    background-color: #ffffff !important;
    border-radius: 12px !important;
    font-family: 'Noto Sans';
    position: relative;

    &.Selected {
        background-color: #f7f7f7 !important;
    }

    span {
        margin: 0;
    }

    .Title {
        font-size: 1.3rem;
        font-weight: 600;
    }

    .Description {
        font-size: 0.9rem !important;
        color: #3d4551;
    }

    .Details {
        font-size: 0.9rem !important;
        color: #3d4551;
    }

    .Location {
        font-size: 0.9rem !important;
        color: #3d4551;
    }

    .DirectionSignIcon {
        width: 20px;
    }

    .Icon {
        width: 22px;
    }

    .CalendarIcon {
        width: 22px;
        height: 14px;
    }

    .ClockIcon {
        width: 22px;
    }

    .ClientBadge {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .ClientLogo {
        width: 60px;
    }

    .ActionsContainer {
        position: absolute;
        z-index: 10;
        right: 0.5rem;
        bottom: 0.5rem;
    }
}

@media screen and (max-width: 883px) and (min-width: 768px) {
    .RegisterButtonContentSvg {
        width: 24px;
        height: 24px;
    }
}

@media screen and (max-width: 413px) and (min-width: 0px) {
    .RegisterButtonContentSvg {
        width: 24px;
        height: 24px;
    }
}
