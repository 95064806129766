.ClientBadge {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  background-color: #FFFFFF;
  color: #3D4551;
  border-width: 0;
}

.ClientLogo {
  width: 60px !important;
}