// This will be later moved to a different file on a different branch
$font-family-base: 'Noto Sans';
$gray-border: #cac4d0;
$black-font: #000000;
$gray-background-color: #efebf1;

.OpenMapAppModal {
    padding: 2rem 2.5rem !important;

    .Title {
        font-family: $font-family-base;
        font-size: 1rem;
    }

    .CloseIcon {
        width: 14px;

        &:hover {
            cursor: pointer;
        }
    }

    a {
        line-break: anywhere;
        text-decoration: none;
        font-family: $font-family-base;
        font-size: 1rem;
        color: $black-font;
        border: 1px $black-font solid !important;
        border-radius: 4px !important;
        width: 200px;

        img {
            width: 50px;
        }

        &:hover {
            background-color: $gray-background-color;
            cursor: pointer;
        }
    }
}
