.General {
    font-family: 'Noto Sans';
}

.Title {
    font-size: 1.3rem;
    font-weight: 600;
}

.InnerText {
    font-size: 0.9rem !important;
}

.DirectionSignIcon {
    width: 20px;
}

.GeneralIcon {
    width: 22px;
}

.CalendarIcon {
    width: 22px;
    height: 14px;
}

.ClockIcon {
    width: 22px;
}

.ClientBadge {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.ClientLogo {
    width: 60px;
}

.RegisterButton {
    font-size: 0.9rem !important;
    font-weight: 600;
}

.RegisterButtonContent {
    span {
        font-size: 0.9rem;
        color: #004e9c;
        text-transform: none !important;
    }
}

.RegisterButton:hover,
.RegisterButton:focus-visible,
.RegisterButton:active {
    .RegisterButtonContent {
        span {
            color: #eef3f8 !important;
        }

        svg {
            stroke: #eef3f8;
            stroke-width: 1px;
        }
    }
}
