.UpcomingWeeklyEvent {
  border-radius: 12px;
  border: 1px solid rgba(158, 158, 158, 0.50);

  .BadgesContainer {
    position: absolute;
    left: 16px;
    top: 16px;
  }

  h3 {
    color: var(--base-ink, #1B1B1B);
    font-family: 'Noto Sans';
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 12px;
  }

  .EventImageCon {
    width: 100%;
    overflow: hidden;
    max-height: 238px;
    position: relative;

    @media screen and (max-width: 568px) {
      max-height: 168px;
    }

    .EventTypesCon{
      position: absolute;
      left: 16px;
      top: 16px;
      gap: 4px;
    }
    .EventImage {
      border-top-left-radius: 14px;
      border-top-right-radius: 14px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .RegistrationBadge {
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
      background-color: #FF5C00;
      color: #FFFFFF;
      font-size: 0.9rem;
      font-family: "Noto Sans";
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;

      img {
        width: 20px;
      }
    }
  }

  .RegisterButton {
    font-size: 0.9rem !important;
    font-weight: 600;
  }

  .RegisterButtonContent {
    span {
      font-size: 0.9rem;
      color: #004E9C;
      text-transform: none !important;
    }
  }

  .RegisterButton:hover, .RegisterButton:focus-visible, .RegisterButton:active {
    .RegisterButtonContent {
      span {
        color: #EEF3F8 !important;
      }

      svg {
        stroke: #EEF3F8;
        stroke-width: 1px;
      }
    }
  }

}
