.EventDetailsImage {
  width: 100%;
  overflow: hidden;
  height: 200px;
  position: relative;
  border-radius: 12px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 568px) {
    height: 168px;
  }

  .EventTypesCon {
    position: absolute;
    left: 16px;
    top: 16px;
    gap: 0.5rem;
  }

  .RegistrationBadge {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    background-color: #FF5C00;
    color: #FFFFFF;
    font-size: 0.9rem;
    font-family: "Noto Sans";
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    img {
      width: 20px;
    }
  }
}