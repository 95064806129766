$PrimaryBlue: #004E9C;
$SecondaryBlue: #E5EDF4;
$BorderRadius: 8px;

.LinkPrimary {
    background-color: #e57200 !important;
    color: #ffffff;
    box-shadow: none !important;
    border: 1px solid #e57200 !important;
    border-radius: 100px !important;
    text-transform: capitalize !important;
    padding: 0.75rem 1.5rem;
    cursor: pointer;
    text-decoration: none !important;

    &:disabled {
        background-color: #f5f5f5 !important;
        color: #79747e !important;
        border: 1px solid #79747e !important;
        cursor: not-allowed;
    }

    &:hover {
        background-color: #f49b45 !important;
        color: #ffffff !important;
    }

    &:focus,
    &:active {
        background-color: #ffffff !important;
        color: #e57200 !important;
    }
}

.LinkPrimaryBlue {
    background-color: $PrimaryBlue !important;
    color: #ffffff;
    box-shadow: none !important;
    border: 1px solid $PrimaryBlue !important;
    border-radius: $BorderRadius !important;
    -webkit-border-radius: $BorderRadius !important;
    -moz-border-radius: $BorderRadius !important;
    -ms-border-radius: $BorderRadius !important;
    -o-border-radius: $BorderRadius !important;
    text-transform: capitalize !important;
    padding: 0.75rem 1.5rem;
    cursor: pointer;
    font-family: 'Noto Sans';
    text-decoration: none !important;

    &:hover {
        background-color: #4b78b0 !important;
    }

    &:focus,
    &:active {
        background-color: #ffffff !important;
        color: $PrimaryBlue !important;
    }
}

.LinkLightBlue {
    background-color: #ffffff !important;
    color: $PrimaryBlue !important;
    box-shadow: none !important;
    border: 2px solid $PrimaryBlue !important;
    border-radius: $BorderRadius !important;
    -webkit-border-radius: $BorderRadius !important;
    -moz-border-radius: $BorderRadius !important;
    -ms-border-radius: $BorderRadius !important;
    -o-border-radius: $BorderRadius !important;
    padding: 0.75rem 1.5rem;
    cursor: pointer;
    font-family: 'Noto Sans';
    text-decoration: none !important;
    &:hover {
        background-color: #f1ebeb !important;
        color: $PrimaryBlue !important;
    }

    &:focus,
    &:focus-visible,
    &:active {
        background-color: $PrimaryBlue !important;
        color: #ffffff !important;
    }

    // mobile
    @media screen and (max-width: 575px) {
        width: 100% !important;
        text-align: center;
    }
}

.LinkPrimaryBlueOutline{
    background-color: #ffffff !important;
    color: $PrimaryBlue !important;
    box-shadow: none !important;
    border: 2px solid $PrimaryBlue !important;
    border-radius: $BorderRadius !important;
    -webkit-border-radius: $BorderRadius !important;
    -moz-border-radius: $BorderRadius !important;
    -ms-border-radius: $BorderRadius !important;
    -o-border-radius: $BorderRadius !important;
    text-transform: capitalize !important;
    padding: 10px 18px !important;
    text-decoration: none;
    cursor: pointer;
    font-family: 'Noto Sans';
    font-weight: 600 !important;
    &:disabled {
        background-color: #E0E0E0 !important;
        color: #8D8D8D !important;
        border: 0 !important;
        cursor: not-allowed;
    }
    &:hover{
        color: $PrimaryBlue !important;
        background: $SecondaryBlue !important;
    }
}
