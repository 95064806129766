.FilterBadge {
    font-family: 'Noto Sans';
    font-size: 0.9rem;
    font-weight: 600;
    color: #00366b;
    border-radius: 4px;
    background-color: #fffbfe;
    border: 2px solid #00366b !important;

    span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 120px;
    }

    img {
        width: 20px;
        cursor: pointer;
    }
}

.WebFiltering {
    max-width: 1600px;
    min-width: 768px;
    margin-left: auto !important;
    margin-right: auto !important;
}

$WIDTH_MAX_1: 1384px;
$WIDTH_MAX_2: 1054px;
$WIDTH_MAX_3: 897px;

.Search {
    width: 28%;

    @media screen and (max-width: $WIDTH_MAX_2) {
        width: 30%;
    }
}

.State,
.Zipcode {
    width: 12%;
    display: flex;

    @media screen and (max-width: $WIDTH_MAX_1) {
        width: 14%;
    }
    @media screen and (max-width: $WIDTH_MAX_2) {
        width: 17%;
    }
    @media screen and (max-width: $WIDTH_MAX_3) {
        width: 15%;
    }
}

.EventType {
    width: 12%;
    display: flex;

    @media screen and (max-width: $WIDTH_MAX_1) {
        width: 14%;
    }
    @media screen and (max-width: $WIDTH_MAX_2) {
        display: none;
    }
}

.HealthPlan,
.Language {
    width: 12%;
    display: flex;

    @media screen and (max-width: $WIDTH_MAX_1) {
        display: none;
    }
}

.ViewMode {
    width: 12%;
    display: flex;

    @media screen and (max-width: $WIDTH_MAX_1) {
        width: 16%;
    }
    @media screen and (max-width: $WIDTH_MAX_2) {
        width: 19%;
    }
    @media screen and (max-width: $WIDTH_MAX_3) {
        width: 25%;
    }
}

.FiltersButton {
    display: none;
    width: 12%;
    height: 66px;

    span {
        font-size: 0.8rem;
    }

    @media screen and (max-width: $WIDTH_MAX_1) {
        display: flex;
        width: 14%;
    }
    @media screen and (max-width: $WIDTH_MAX_2) {
        width: 17%;
    }
    @media screen and (max-width: $WIDTH_MAX_3) {
        width: 15%;
    }
}
