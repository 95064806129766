.Carousel {
  border-radius: 12px;
  .CarouselLeftControlContainer {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    height: 100%;
    width: 55px;
  }

  .CarouselRightControlContainer {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    height: 100%;
    width: 55px;
  }

  .CarouselControl {
    width: 45px;
    height: 45px;
    border-radius: 22px;
    background-color: #FFFFFF;
    cursor: pointer;

    img {
      width: 10px;
    }
  }
}