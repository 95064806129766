.Locator,
.LocatorMobile {
    position: absolute;
    z-index: 50;
    border: 2px solid rgba(158, 158, 158, 0.5);
    border-radius: 40px;
    background-color: #ffffff;

    &:focus-within {
        border: 2px solid #00366b;
    }

    .Icon {
        width: 18px;
    }

    .ClearIcon {
        width: 18px;
        cursor: pointer;
    }

    input {
        border: none;
        font-family: 'Noto Sans';
        font-size: 0.9em;
        width: 90px;
        color: #00366b;

        &:focus-visible,
        &:focus {
            outline: none;
        }
    }
}

.Locator {
    left: 40%;
    margin-top: 5rem !important;
}

.LocatorMobile {
    right: 0;
}
