.SignInPanel {

  .MessageBox {
    border-radius: 12px;
    background-color: #F3F7FB;

    span {
      font-family: "Noto Sans";
      font-size: 1.3rem;
      font-weight: 600;
      color: #004E9C;
    }

    p {
      font-family: "Noto Sans";
      font-size: 1rem !important;
      color: #3370AC;
    }
  }

  .SubmitButton {
    height: 48px;
    font-size: 1rem !important;
  }

}
