.ViewModeSwitch {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 20;
    font-family: 'Noto Sans';
    font-size: 0.9rem;
    font-weight: 600;
    border-radius: 40px;
    background-color: #3d4551;
    color: #ffffff;

    img {
        width: 20px;
    }
}
