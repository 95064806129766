.ViewModeWebSwitch {
    font-family: 'Noto Sans';
    height: 68px;

    .Title {
        font-size: 0.8rem;
    }

    .ViewModeWebSwitchButtons {
        cursor: pointer;

        .MapButton {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            border-left: 1px solid #9e9e9e80;
            border-top: 1px solid #9e9e9e80;
            border-bottom: 1px solid #9e9e9e80;

            span {
                font-size: 0.8rem;
            }
        }

        .ListButton {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            border-right: 1px solid #9e9e9e80;
            border-top: 1px solid #9e9e9e80;
            border-bottom: 1px solid #9e9e9e80;

            span {
                font-size: 0.8rem;
            }
        }

        .Active {
            background-color: #00366b;
            color: #ffffff;
        }

        .Inactive {
            background-color: #ffffff;
            color: #1b1b1b;
        }

        img {
            width: 14px;
        }

        span {
            font-size: 0.9rem;
            font-weight: 600;
        }
    }

    @media screen and (max-width: 1070px) {
        .MapModeWideContainer {
            display: none !important;
        }

        .MapModeSmallContainer {
            display: flex !important;
        }
    }

    @media screen and (min-width: 1070px) {
        .MapModeWideContainer {
            display: flex !important;
        }

        .MapModeSmallContainer {
            display: none !important;
        }
    }

    @media screen and (max-width: 700px) {
        .EventsModeWideContainer {
            display: none !important;
        }

        .EventsModeSmallContainer {
            display: flex !important;
        }
    }

    @media screen and (min-width: 700px) {
        .EventsModeWideContainer {
            display: flex !important;
        }

        .EventsModeSmallContainer {
            display: none !important;
        }
    }
}
