.DropdownContainer {
    height: 68px;
    width: 100%;
}

.label {
    font-size: 0.8rem !important;
}

.Dropdown {
    font-family: 'Noto Sans';
    font-size: 0.8rem !important;
    background-color: #ffffff;
    border: 1px solid #9e9e9e80;
    border-radius: 8px;
    font-weight: 600;
    padding: 0.7rem 1rem;
    cursor: pointer;
    transition: border 0.08s ease;

    .DropdownSelectedValue {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 200px;
    }

    &.Disabled {
        cursor: not-allowed;
        background-color: #f1efef;
    }

    &:hover {
        border: 2px solid #00366b;
        transition: border 0.08s ease;

        .DropdownSelectedValue {
            color: #00366b;
        }
    }

    .ArrowDown {
        animation: spinDown 0.3s linear;
        animation-fill-mode: forwards;
    }

    .ArrowUp {
        animation: spinUp 0.3s linear;
        animation-fill-mode: forwards;
    }

    @keyframes spinUp {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(180deg);
        }
    }

    @keyframes spinDown {
        from {
            transform: rotate(180deg);
        }
        to {
            transform: rotate(0deg);
        }
    }
}

.DropdownOptionsContainer {
    font-family: 'Noto Sans';
    font-size: 0.8rem !important;
    background-color: #ffffff;
    border: 2px solid #00366b;
    border-radius: 4px;
    position: absolute;
    z-index: 10;
    //top: 75px;
    height: auto;
    display: none;

    .Option {
        padding: 0.7rem 1rem;
        cursor: pointer;
        height: 47px;

        &:hover {
            background-color: #f7f7f7;
        }

        .label {
            font-size: 0.8rem !important;
        }

        &.general {
            border-radius-top-left: 4px;
            border-radius-top-right: 4px;
            border-bottom: 1px solid rgba(158, 158, 158, 0.5);
        }
    }

    .CheckmarkBox {
        padding: 0 !important;
        width: 20px;
        min-width: 20px;
        height: 20px;
        min-height: 20px;
        background-color: #ffffff;
        color: #ffffff;
        border: 2px solid black;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        cursor: pointer;
    }

    .CheckboxInput[type='checkbox']:checked ~ div {
        background-color: #ffffff !important;
    }

    .CheckboxInput[type='checkbox']:checked ~ div svg {
        color: black !important;
    }
}

.Opened {
    display: block;
}

.MenuCurtain {
    background-color: #ffffff;
    position: absolute;
    z-index: 11;
    top: 64px;
    height: auto;
    border-radius: 4px;
}

.ScrollUp {
    animation: conditionalClose 0.3s normal forwards ease-in-out;
    transform-origin: 100% 0%;
}

.ScrollDown {
    animation: conditionalClose 0.2s normal forwards ease-in-out;
    transform-origin: 0% 100%;
}

.Actions {
    border-top: 1px solid rgba(158, 158, 158, 0.5);
    height: 61px;

    p {
        font-size: 0.8rem;
        color: #00366b;
        font-weight: 600;
        cursor: pointer;
    }

    button {
        font-size: 0.8rem;
        font-weight: 600;
    }
}

@media screen and (min-width: 1512px) {
    .Actions {
        button {
            padding-right: 1.2rem;
            padding-left: 1.2rem;
        }
    }
}

@media screen and (max-width: 1511px) and (min-width: 1200px) {
    .Actions {
        button {
            padding-right: 0.2rem;
            padding-left: 0.2rem;
        }
    }
}

@media screen and (max-width: 1199px) and (min-width: 1010px) {
    .Actions {
        button {
            padding-right: 1rem;
            padding-left: 1rem;
        }
    }
}

@media screen and (max-width: 1009px) {
    .Actions {
        button {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
        }
    }
}

@-webkit-keyframes conditionalOpen {
    from {
        transform: scaleY(0);
    }
    to {
        transform: scaleY(1);
    }
}
@-moz-keyframes conditionalOpen {
    from {
        transform: scaleY(0);
    }
    to {
        transform: scaleY(1);
    }
}
@keyframes conditionalOpen {
    from {
        transform: scaleY(0);
    }
    to {
        transform: scaleY(1);
    }
}

@-webkit-keyframes conditionalClose {
    from {
        transform: scaleY(1);
    }
    to {
        transform: scaleY(0);
    }
}
@-moz-keyframes conditionalClose {
    from {
        transform: scaleY(1);
    }
    to {
        transform: scaleY(0);
    }
}
@keyframes conditionalClose {
    from {
        transform: scaleY(1);
    }
    to {
        transform: scaleY(0);
    }
}
