.carousel-control-prev {
  opacity: 1 !important;
  justify-content: start !important;
  align-items: end !important;
  margin-left: -30px !important;
  bottom: 50px !important;
}

.carousel-control-next {
  opacity: 1 !important;
  justify-content: end !important;
  align-items: end !important;
  margin-right: -30px !important;
  bottom: 50px !important;
}
