.OtherEvents {
  margin-bottom: 100px;
  .RelatedEventSecTitle{
    h3{
      color: #262626;
      font-family: 'Noto Sans';
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .RelatedEventsCon{
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    .RelatedEvent{
      width: calc(50% - 16px);
      @media screen and (max-width: 568px){
        width: 100%;
      }

    }

  }
  .RelatedEventsNote{
    border-radius: 12px;
    background: #F3F7FB;
    p {
      color: #3D4551;
      font-family: 'Noto Sans';
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }

  .RecommendedEventRightDivider {
    padding-left: 0;
    padding-right: 0;

    @media screen and (min-width: 768px) {
      padding-right: 0.25rem;
    }
  }

  .RecommendedEventLeftDivider {
    padding-left: 0;
    padding-right: 0;

    @media screen and (min-width: 768px) {
      padding-left: 0.25rem;
    }
  }
}