.StepActions {
  border-top: 1px solid #E0E0E0;

  @media screen and (max-width: 576px) {
    border-top: 0;
  }

  .RegistrationModalLink {
    min-width: 100%;
    height: 100%;

    @media screen and (max-width: 340px) {
      font-size: 0.7rem;
    }
  }

  .RegistrationModalButtonBack,
  .RegistrationModalButtonRight {
    width: auto;
    height: 100%;
    font-family: 'Noto Sans';
    @media screen and (max-width: 786px) {
      width: 100%;
    }
  }
}
