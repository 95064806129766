.LocationNotProvidedOverlay {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 24px;
  color: #004E9C;
  font-weight: 500;

  .Label {
    color: #004E9C;
  }

  .OverlayModal {
    padding: 24px;
    border-radius: 12px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 396px;

    img {
      width: 16px;
      height: 16px;
    }
  }
}