.Checkbox {
    display: flex;
    justify-content: flex-start;

    label {
        padding-left: 1rem;
        font-size: 1.2rem;
        font-family: 'Noto Sans';
        cursor: pointer;
        color: #9e9e9e;
    }

    .icon {
        cursor: pointer;
    }
}

.CheckmarkBox {
    padding: 0 !important;
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
    background-color: #ffffff;
    color: #ffffff;
    border: 2px solid #e57200;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}
