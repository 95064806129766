.RegistraionCompltModalDialog{
    position: fixed !important;
    height: 100vh !important;
    width: 80vw !important;
    right: 0 !important;
    top: 0 !important;
    max-width: 837px !important;
    margin: 0 !important;
    font-family: 'Noto Sans';
    transform: translateX(100%);
    animation: slideLeft 0.5s 1 forwards;

    @media screen and (max-width: 768px) {
        width: 100vw !important;
        max-width: 100vw !important;
    }

    .RegistraionCompltModalContent{
        width: 100%;
        height: 100%;
        padding: 67px 69px !important;
        overflow-y: auto;
        border: 0 !important;
        border-radius: 0.5rem 0 0 0.5rem;

        @media screen and (max-width: 768px) {
            border-radius: 0;
            padding: 30px !important;
        }
        @media screen and (max-width: 668px) {
            padding: 0 !important;
        }

        h2{
            color: #262626;
            font-family: 'Montserrat';
            font-size: clamp(20px, 2.2vw, 34px);
            font-style: normal;
            font-weight: 700;
            line-height: normal; 
            margin: 0;
        }
        .RegistraionCompltModalHeader{
            padding: 0 !important;
            padding-bottom: 32px !important;
            border-bottom: 1px solid #E0E0E0 !important;
            @media screen and (max-width: 668px){
                padding: 16px !important;
            }
        }
        .RegistraionCompltModalBody{
            padding: 32px 0 0 !important;

            @media screen and (max-width: 668px){
                padding: 16px !important;
            }

            .ModalFooterButtons{
                box-shadow: 0px -6px 30px 0px rgba(0, 0, 0, 0.10);
                background: #FFF;
                padding: 19px 68px;
                position: fixed;
                bottom: 0;
                left: 0;
                width: 100%;
                max-width: 836px;
                @media screen and (max-width: 768px) {
                    width: 100%;
                }
                @media screen and (max-width: 668px){
                    padding: 16px !important;
                    button{
                        width: 100%;
                    }
                }
            }
        }
        .ItemNumb{
            width: 45px;
            min-width: 45px;
            height: 45px;
            min-height: 45px;
            border-radius: 50%;
            border: 0;
            background: #E5EDF4;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #004E9C;
            font-family: 'Montserrat';
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal; 
        }

        .RegistrationCompleteCon{
            padding: 0;
            height: 100%;
            max-width: 100%;
            overflow-x: hidden;

            
            .EventMapContainer{
                margin-bottom: 32px;
                border-radius: 8px;
                overflow: hidden;
                @media screen and (max-width: 568px) {
                    margin-bottom: 16px;
                }
            }
            .EvenDetailsCon{
              max-height: max-content;

              .EventTitle{
                color: #262626;
                font-family: 'Montserrat';
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: 127.273%;
                margin-bottom: 12px;
              }
              .EventDescCon {
                overflow: hidden;
                margin-bottom: 24px;
              
                @media screen and (max-width: 568px) {
                  margin-bottom: 0;
                }
              
                .EventInfoRow {
                    display: flex;
                    gap: 12px;
                    img{
                        width: 16px;
                        height: auto;
                        object-fit: contain;
                        object-position: center;
                    }
                    &:nth-child(3){
                        margin-bottom: 0;
                    }
                    span{
                      color: var(--base-ink, #1B1B1B);
                      font-family: 'Noto Sans';
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: normal; 
                    }
                }
                .CalendarIcon{
                    margin-top: 2px;
                }
                .ClockIcon{
                    margin-top: 4px;
                }
              }
              @media screen and (max-width: 992px) {
                border-right: 0;
              }
            }
            .DetailsSectionRight{
              padding: 0;
              .ItemTitleRow{
                display: flex;
                align-items: flex-start;
                gap: 16px;
              }
              .DividerFull{
                margin: 32px 0;
              }
              p{
                color: #262626;
                font-family: 'Noto Sans';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin: 0; 
                margin-top: 16px;
                @media screen and (max-width: 568px) {
                    margin-top: 0;
                }
              }
          
              .PromotionalVideo{
                width: 100%;
                height: 349px;
                border-radius: 4px;
                overflow: hidden;
                margin: 32px 0;
                border-radius: 8px;
                @media screen and (max-width: 568px){
                    height: 193px;
                }
                iframe{
                  width: 100%;
                  height: 100%;
                }
              }
              .ButtonRow{
                display: flex;
                align-items: center;
                margin-top: 16px;
                .LogoCon{
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    .MemberAppLogo{
                        width: 40%;
                        max-width: 57px;
                        object-fit: contain;
                    }
                    h4{
                        color: #00366B;
                        font-family: 'Noto Sans';
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal; 
                        margin: 0;
                    }
                }
                .DownloadButton{
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 12px;

                  @media screen and (max-width: 568px){
                    width: auto !important;
                  }
                  .BtnIcon{
                    width: 16px;
                  }
                }
              }
            }
            .EventBtns{
              gap: 12px;
              Button{
                text-transform: none !important;
              }
              @media screen and (max-width: 568px){
                flex-direction: column-reverse;
                position: fixed;
                width: 100%;
                border-top: 1px solid rgba(158, 158, 158, 0.50);
                background: #FFF;
                padding: 16px;
                left: 0;
                bottom: 0;
                button{
                  width: 100%;
                  padding: 16px 20px !important;
                }
              }
            }
            
            .Divider{
                width: 100%;
                height: 1px; 
                background: #E0E0E0;
                margin: 32px 0;
            }

            .RegCompltModalFormControl{
                width: 100%;
                border-radius: 8px;
                border: 1px solid #E0E0E0;
                background: #FFF;
                max-width: 285px;
                padding: 11px 14px;
                color: #262626;
                font-family: 'Noto Sans';
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
            }
            .Icon{
                width: 16px;
            }
            
        }
        .FormCheckBox{
            width: 24px;
            height: 24px;
        }
        
        @media screen and (max-width: 568px){
            .RegistrationCompleteCon{
                flex-wrap: nowrap !important;
                align-items:flex-start !important;
                padding: 0;
                max-width: 100vw;
                height: auto;
                max-height: max-content;
                overflow-x: hidden;
                overflow-y: auto;
                padding-bottom: 15px;
                &::-webkit-scrollbar{
                    display: none;
                }
                .ItemTitleRow{
                    flex-direction: column;
                    align-items: flex-start !important;
                } 
                .evenDetailsCon, .DetailsSectionRight{
                    justify-content: flex-start !important;
                    width: 100vw !important;
                    padding: 16px;
                }
                .DetailsSectionRight{
                    overflow-y: auto;
                    .ItemOne{
                        // min-height: calc(100vh - 250px);
                        height: auto;
                    }
                }
                .DividerFull{
                    display: none;
                }
                .ItemTwo{
                    padding-bottom: 60px;
                    .LogoCon{
                        width: 100%;
                        margin-bottom: 24px;
                    }
                }
                .ButtonRow{
                    flex-direction: column;
                    Button{
                        width: 100%;
                    }
                }
                .ItemTwo{
                    .ButtonRow, .LogoCon, .PromotionalVideo{
                        position: relative;
                        background: #ffffff;
                    }
                }
            }
        }
    }
}

@keyframes slideLeft {
    from{
        transform: translateX(100%);
    }
    to{
        transform: translateX(0);
    }
}