.Container {
    font-family: 'Noto Sans';
    //max-width: 651px;
  flex: 1;
}

.Title {
    font-size: 1.3rem;
    font-weight: 600;
}

.Paragraph {
    font-size: 1.2rem;
}

.BubbleActive {
    background-color: #004e9c;
    border-radius: 20px;
    cursor: pointer;
    max-width: 200px;

    span {
        color: #ffffff;
        font-size: 0.9rem;
    }
}

.BubbleInactive {
    background-color: #E0E0E0;
    border-radius: 20px;
    cursor: pointer;
    max-width: 200px;

    span {
        color: #6F6F6F;
        font-size: 0.9rem;
    }
}

  .MessageBox {
    margin-top: 16px;
    border-radius: 12px;
    background-color: #F3F7FB;
    text-align: center;
    font-size: 1rem;
    font-family: "Noto Sans";

    span {
      color: #004E9C;
    }

    p {
      font-family: "Noto Sans";
      color: #3370AC;
    }
}

  .VirtualEventsListButton, .InPersonEventsListButton {
    font-size: 0.8rem;
    border-radius: 1.5rem !important;
    font-weight: 600;

    &:hover {
      border: 1px solid #00366B !important;
    }
  }
