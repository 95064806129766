.ShareModal {
    padding: 2rem 2.5rem !important;

    .Title {
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-weight: 700;
    }

    .Paragraph {
        font-family: 'Noto Sans', sans-serif;
        font-size: 1rem;

        a {
            line-break: anywhere;
            text-decoration: none;
        }
    }
}
