.Title {
  font-size: 1.3rem;
  font-weight: 600;
}

.Countdown {
  font-size: 1rem;
  color: #999999;
  font-weight: 600;

  @media screen and (max-width: 512px) {
    font-size: 0.9rem !important;
  }
}

.NationwideEventsListCarousel {
  .NextAndPreviousButton {
    width: 46px;
    height: 46px;
    border-radius: 23px !important;
    border: none !important;
    box-shadow: 1px 4px 5px rgba(0,0,0,0.12) !important;

    img {
      width: 20px;
      height: 16px;
    }

    &:hover, &:active, &:focus-visible, &:focus {
      background-color: #efeeee !important;
    }
  }
}

.Indicators {
  justify-content: center;

  @media screen and (max-width: 512px) {
    justify-content: space-between;
  }

  .Dot {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: #E5EDF4;
    cursor: pointer;
  }

  .Selected {
    background-color: #004E9C !important;
  }

  .ArrowIcon {
    cursor: pointer;

    img {
      width: 20px;
      height: 16px;
    }
  }

  .Disabled {
    opacity: 0.3;
  }
}