.RegistrationModalDialog {
    max-width: 983px !important;
    border-radius: 12px !important;
    // mobile
    @media screen and (max-width: 575px) {
        min-width: initial !important;
        margin: 0 !important;
        --bs-modal-border-radius: 0 !important;
    }
}
.RegistrationModal {
    border-radius: 12px !important;
    padding: 32px !important;
    @media screen and (max-width: 575px) {
        padding-top: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-bottom: 1rem !important;
    }
}

.RegistrationModalDialogStep2 {
    max-width: 502px !important;
    border-radius: 12px !important;
    // mobile
    @media screen and (max-width: 575px) {
        min-width: initial !important;
        margin: 0 !important;
    }
}

.RegistrationModalDialogStep4 {
    border-radius: 12px !important;
    // mobile
    @media screen and (max-width: 575px) {
        min-width: initial !important;
        margin: 0 !important;
    }
}

.RegistrationModalContainer {
    max-width: 983px !important;
    border-radius: 12px !important;
    border-width: 0;
    // mobile
    @media screen and (max-width: 575px) {
        min-width: initial !important;
        height: 100vh;
        border-radius: 0 !important;
    }

    // mobile
    @media screen and (min-width: 575px) {
        border-radius: 12px !important;
    }
}

.StepZeroModalContainer {
    height: auto;
    margin: 0 16px;
}

.MobileFullScreenHeight {
    @media screen and (max-width: 575px) {
        height: 100vh;
        margin: 0 !important;
        overflow-y: auto;
    }
}
.ModalContentRow{
    @media screen  and (max-width: 768px){
        height: 84% !important;
    }
}

.ModalTitleStepOne {
    color:#1B1B1B;
    text-align: center;
    font-family: 'Noto Sans';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    // mobile
    @media screen and (max-width: 575px) {
        font-size: 22px !important;
    }
}
.ModalMissionTitleStepOne{
    color: #1B1B1B;
    font-family: 'Noto Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media screen and (max-width: 575px) {
        font-size: 16px !important;
    }
}

.ModalSubTitleStepOne {
    font-weight: 400;
    color: #3D4551;
}

.RegistrationModalTitle {
    font-family: 'Noto Sans';
    font-size: 1.5rem;
    font-weight: 700;
}

.RegistrationModalParagraph {
    font-family: 'Noto Sans';
    font-size: 1rem;
    color: #3D4551;
}

.RegistrationModalButton {
    // max-width: calc(50%);
    width: auto;
    height: 100%;
    font-family: 'Noto Sans';
    @media screen and (max-width: 575px) {
        min-width: initial !important;
    }
}
.RegistrationModalButtonBack,
.RegistrationModalButtonRight{
    width: auto;
    height: 100%;
    font-family: 'Noto Sans';
    @media screen and (max-width: 786px) {
        width: 100%;
    }
}


.RegistrationModalLink {
    min-width: 100%;
    height: 100%;

    @media screen and (max-width: 340px) {
        font-size: 0.7rem;
    }
}

.ModalDescription {
    color: #3D4551;
    font-family: 'Noto Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}
.ModalSiteLogo{
    max-width: 105px;
}
.ModalImage {
    flex-grow: 1;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.ModalSliderItemStep1{
    p{
        width: 90%;
        max-width: 965px;
        color: #000;
        text-align: center;
        font-family: Poppins;
        font-size: 22px;
        font-style: italic;
        font-weight: 500;
        line-height: 177.727%;
        @media screen and (max-width:568px) {
            font-size: 16px;
        }
    }
}

.BgMainLight {
    background-color: #fff;
    border-radius: 12px;
    border: 1px solid #E0E0E0;
}
.ModalHeaderText{
    background: #E1F3F8;
    margin-bottom: 32px;
    a, p{
        color: #07648D;
        font-family: 'Noto Sans';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    @media screen and (min-width: 576px) {
        border-radius: 12px;
    }
}

.StepActions {

}