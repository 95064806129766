.EventsFilteringContainer {
    border-bottom: 1px solid rgba(158, 158, 158, 0.5) !important;
    position: relative;
    background-color: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(15px);
    z-index: 10;
}

.WebContainer {
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    height: 86px;
}

.WebContainer::-webkit-scrollbar {
    display: none;
}

.Title {
    font-weight: 600;
    font-size: 2rem;
    font-family: 'Noto Sans';
}

.Paragraph {
    font-size: 1.2rem;
    font-family: 'Noto Sans';
}

.EventFiltering_Checkbox {
    display: flex;
    justify-content: flex-start;

    label {
        padding-left: 0.6rem;
        font-size: 0.9rem;
        font-family: 'Noto Sans';
        cursor: pointer;
        color: #000000;
    }

    input[type='checkbox'] {
        visibility: hidden;
    }

    input[type='checkbox']:checked ~ div {
        background-color: #005ea2;
        border-color: #005ea2;
    }

    input[type='checkbox']:checked ~ div .bi-check2::before {
        color: #ffffff;
        font-weight: bold !important;
    }

    input[type='checkbox']:checked ~ div .bi-check2 {
        display: block;
    }

    input[type='checkbox'] ~ div .bi-check2 {
        display: none;
    }
}

.EventFiltering_CheckmarkBox {
    padding: 0 !important;
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
    background-color: #ffffff;
    color: #ffffff;
    border: 2px solid #000000;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.ResetButtonContainer {
    padding-top: 0.13rem;
    padding-bottom: 0.13rem;

    .Separator {
        height: 100%;
        width: 1px;
        background-color: #9e9e9e;
    }

    .SeparatorSpace {
        height: 100%;
        width: 0.5rem;
    }

    .CloseIcon {
        width: 8px;
    }
}

.ResetButton {
    height: 44.8px !important;

    span {
        font-size: 0.9rem;
        font-weight: 600;
    }

    img {
        width: 16px;
    }
}
