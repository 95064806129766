.EventDetailsMiscellaneous {
  h5 {
    color: #262626;
    font-family: 'Noto Sans';
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 12px;
  }
  .Attendees {
    color: #262626;
    font-family: 'Noto Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .PartnerLogo{
    display: inline-block;
    width: auto;
    max-width: 100px;
    border-radius: 4px;
  }
  .HostInfoTitle{
    color: var(--base-ink, #1B1B1B);
    font-size: 18px;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 12px;
  }
  .HostProfileImg{
    border-radius: 50%;
    width: 60px;
    min-width: 60px;
    height: 60px;
    min-height: 60px;
    overflow: hidden;
    background: #FFE8BC;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #E57200;
    font-family: 'Montserrat';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .AttendeesCon{
    .userAvatar{
      width: 20px;
    }
  }
  .EventInfoRow {
    display: flex;
    gap: 8px;
    margin-bottom: 10px;
    img{
      width: 16px;
      height: auto;
      object-fit: contain;
      object-position: center;
    }
    span{
      color: var(--base-ink, #1B1B1B);
      font-family: 'Noto Sans';
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
.DividerFull {
  height: 1px;
  width: 100%;
  background: rgba(158, 158, 158, 0.50);
}

.ClientLogo {
  width: 100%;
  height: auto;
  object-fit: contain;
}
