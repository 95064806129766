.RadioButton {
    display: flex;
    justify-content: flex-start;

    label {
        padding-left: 1rem;
        font-size: 1.2rem;
        font-family: 'Noto Sans';
        cursor: pointer;
        color: #9e9e9e;
    }

    .icon {
        cursor: pointer;
        width: 24px;
        height: 24px;
    }
}
