.EventDetailsBox {
    width: 35vw !important;
    font-family: 'Noto Sans';
    border-radius: 12px !important;

    @media screen and (max-width: 900px) {
        width: 60vw !important;
    }

    .EventTitle {
        font-size: 1.1rem;
        font-weight: 600;
    }

    .EventDateAndTime {
        font-size: 0.9rem;
    }

    .EventLocation {
        font-size: 0.9rem;
    }

    .EventListItem_JoinButton {
        font-size: 0.8rem !important;
        padding: 0.3rem 0.5rem !important;
    }

    .CloseIcon {
        width: 18px;
        height: 18px;
        cursor: pointer;
    }

    .DirectionSignIcon {
        width: 18px;
    }

    .Icon {
        width: 20px;
    }

    .CalendarIcon {
        width: 20px;
        height: 12px;
    }

    .ClockIcon {
        width: 20px;
    }

    .ClientBadge {
        padding: 0 0.2rem !important;
    }

    .ClientLogo {
        width: 50px;
    }

    @media screen and (max-width: 768px) {
        min-width: 210px;
    }
}

.EventMarker {
    font-family: 'Noto Sans';
    margin-top: 0px;
    cursor: pointer;

    .MarkerIcon {
        width: 64px;
        height: 72px;
    }

    .MarkerNumber {
        border-radius: 25px;
        background-color: #ff5c00;
        //width: 22px;
        //height: 22px;
        position: absolute;
        //right: -60px;
        top: 0;

        span {
            color: white;
            //font-size: 0.9rem;
            font-weight: 600;
        }
    }
}
