.Container {
  height: 100vh;
}

.WCLogoLoading {
  width: 30%;

  @media screen and (max-width: 768px)  {
    width: 40%;
  }
}

.SmallMessage {
  font-size: 2rem;
  font-family: 'Noto Sans';
  color: #bfc0c0;
  font-weight: 700;
}

.Bounce {
  animation: bounce-out-down 2s ease infinite;
}

@keyframes bounce-out-down {
  0% { transform: translateY(0); }
  20% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(20px);
  }
}
