.EventDetailsHeader {
  span svg{
    padding: 0 !important;
    width: auto;
  }

  h4{
    color: #1B1B1B;
    font-family: 'Noto Sans';
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }

  @media screen and (min-width: 576px) {
    border-bottom: none !important;
    padding-left: 69px;
    padding-right: 69px;
    padding-top: 67px;
    padding-bottom: 0 !important;
  }

  @media screen and (max-width: 576px) {
    position: fixed;
    //background-color: #FFF;
    z-index: 10;
    width: 100%;
    padding: 16px;
  }

  .EventTitle {
    color: #262626;
    font-family: 'Noto Sans';
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

}
