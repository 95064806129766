#tooltip-container {
}

#tooltip-container > .tooltip-inner {
    background-color: rgba(32, 32, 32, 0.6);
    margin-top: 1px;
    border-radius: 4px;
}

#tooltip-container > .tooltip-arrow {
    border-bottom-color: rgba(32, 32, 32, 0.6) !important;
    left: -2.5rem !important;
}

#tooltip-container > .tooltip-arrow::before {
    border-bottom-color: rgba(32, 32, 32, 0.6) !important;
}
