@import 'form-controls';
@import 'fonts.scss';

$main: #00366b;
$black: #1b1b1b;
$light: #eef3f8;

body {
    font-family: 'Noto Sans';
    color: $black;
}

.font-sourceSerif {
    font-family: 'SourceSerifPro';
}

.font-sourceSerif-semi {
    font-family: 'SourceSerifPro';
    font-weight: 600;
}

.font-sourceSerif-bold {
    font-family: 'SourceSerifPro';
    font-weight: bold;
}

.font-sourceSerif-light {
    font-family: 'SourceSerifPro';
    font-weight: 300;
}

.text-main {
    color: $main;
}

.bg-main {
    background-color: $main;
}

.bg-main-light {
    background-color: $light;
}

.border-main {
    border-color: $main;
}

.cursor-pointer {
    cursor: pointer;
}

.WC_Carouse {
    .carousel-slider {
        border-radius: 12px !important;
    }
}
