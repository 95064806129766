.InputContainer {
    display: flex;
    align-items: center;
    height: 100%;

    input {
        margin: 0 !important;
        height: 100% !important;
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
        outline: none !important;
        padding: 12px 14px;
        border-radius: 0.4rem;
        border: 1px solid rgba(158, 158, 158, 0.5);
        height: 48px;
        color: #1b1b1b;
        &::placeholder {
            color: #1b1b1b;
        }
    }

    input:focus,
    input:focus-visible {
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
    }
}

.InputInvalid {
    border: 1px solid #d80606 !important;

    label {
        color: #d80606 !important;
    }
}

.InvalidLabel {
    color: #d80606 !important;
}
