.Container {
    height: 100vh;
}

.SmallImage {
    width: 30%;
}

.LargeImage {
    width: 60%;
}

.LargeMessage {
    font-size: 1.2rem;
    font-family: 'Noto Sans';
}

.SmallMessage {
    font-size: 1rem;
    font-family: 'Noto Sans';
}
