@font-face {
    font-family: 'SourceSerifPro';
    src:
        local('SourceSerifPro'),
        url(../fonts/SourceSerifPro-Regular.otf) format('opentype');
}

@font-face {
    font-family: 'SourceSerifPro';
    src:
        local('SourceSerifPro'),
        url(../fonts/SourceSerifPro-Bold.otf) format('opentype');
    font-weight: bold;
}

@font-face {
    font-family: 'SourceSerifPro';
    src:
        local('SourceSerifPro'),
        url(../fonts/SourceSerifPro-Semibold.otf) format('opentype');
    font-weight: 600;
}

@font-face {
    font-family: 'SourceSerifPro';
    src:
        local('SourceSerifPro'),
        url(../fonts/SourceSerifPro-Light.otf) format('opentype');
}
