.StepBoard {
  display: flex;
  align-items: center;
  justify-content: center;

  li {
    border-radius: 14px;
    background-color:#F4F4F4;
    display: flex;
    padding: 5px 12px;
    align-items: flex-start;
    justify-content: center;
    color: #A8A8A8;
    font-family: 'Noto Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;

    span {
      display: none;
    }

    &.active {
      background-color: #004E9C;
      color: #FFFFFF;

      span {
        display: block !important;
      }
    }
  }
}