.EventListItem {
    box-shadow: none !important;
    border: 1px #cac4d0 solid !important;
    background-color: #ffffff !important;
    border-radius: 12px !important;
    font-family: 'Noto Sans';
    position: relative;

    .EventListItemBody {
        padding-left: 0 !important;

        .RegisteredUsersLabel {
            font-weight: normal;
            font-size: 0.9rem;

            img {
                width: 14px;
                height: 14px;
            }
        }
    }

    span {
        margin: 0;
    }

    .Title {
        font-size: 1.3rem;
        font-weight: 600;
    }

    .Description {
        font-size: 0.9rem !important;
    }

    .Details {
        font-size: 0.9rem !important;
    }

    .Location {
        font-size: 0.9rem !important;
        color: #00366b;
    }

    .DirectionSignIcon {
        width: 20px;
    }

    .Icon {
        width: 22px;
    }

    .CalendarIcon {
        width: 22px;
        height: 14px;
    }

    .ClockIcon {
        width: 22px;
    }

    .ClientBadge {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .ClientLogo {
        width: 60px;
    }

    .ImageContainer {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        margin-top: -1rem;
        margin-bottom: -1rem;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
    }

    .ActionsContainer {
        position: absolute;
        z-index: 10;
        right: 0.5rem;
        bottom: 0.5rem;

        .RegisterButton {
            font-size: 0.85rem;
        }
    }
}
