.EventDetailsActions {
  border-top: 1px solid rgba(158, 158, 158, 0.50);
  background: #FFF;

  @media screen and (min-width: 576px) {
    padding: 19px 69px;
  }

  @media screen and (max-width: 576px) {
    padding: 16px;
  }

  .SharingButtonSpacing {
    @media screen and (min-width: 600px) {
      width: 300px;
      height: 40px;
    }
  }
}