.EventsListMobileModal {
    margin-left: 0 !important;
    margin-right: 0 !important;
    font-family: 'Noto Sans';
}

.EventsListMobileModalContent {
    position: fixed !important;
    bottom: 0;
    max-height: 60vh;
    border-radius: 0 !important;

    .Header {
        .TitleContainer {
            background-color: #E5EDF4;
            border-radius: 8px;

            .Title {
                font-size: 1rem;
                font-weight: normal;
                color: #004E9C;
            }

            img {
                width: 14px;
            }
        }
    }

    .EventsListMobileModalBody {
        overflow: auto;

        .Subtitle {
            font-size: 1rem;
        }
    }

    .Title {
        font-size: 1.1rem;
        font-weight: 600;
    }

    .CloseButton {
        font-size: 1rem !important;
        font-weight: normal !important;
        padding-top: 0.4rem !important;
        padding-bottom: 0.4rem !important;

        img {
            height: 12px;
        }
    }

    .DirectionSignIcon {
        width: 20px;
    }

    .Icon {
        width: 22px;
    }

    .CalendarIcon {
        width: 22px;
        height: 14px;
    }

    .ClockIcon {
        width: 22px;
    }

    .Body {
        .SelectableTitles {
            overflow: auto;
            border-right: #9e9e9e 1px solid;

            .Title {
                font-size: 0.9rem !important;
                cursor: pointer;

                &.active {
                    background-color: #eef3f8;
                    border-radius: 4px;
                    cursor: auto;
                }
            }
        }

        .SelectedEventInformation {
            overflow: auto;

            .Title {
                font-size: 1.3rem;
            }

            .Description {
                font-size: 0.9rem !important;
            }

            .Details {
                font-size: 0.9rem !important;
            }

            .Location {
                font-size: 0.9rem !important;
            }

            .RegisterButton {
                font-size: 0.9rem !important;
                padding-top: 0.4rem !important;
                padding-bottom: 0.4rem !important;
            }
        }
    }

    .Actions {
        .ClearButton {
            width: 45%;
        }

        .SubmitButton {
            font-weight: normal;
            width: 45%;
        }
    }
}
