.AfterConfirmationStatus {
  padding-bottom: 5rem;

  .EventMapContainer{
    border-radius: 8px;
    overflow: hidden;

    .CustomInnerMapClass {
      height: 200px;
    }
  }

  .DividerFull {
    height: 1px;
    width: 100%;
    background: rgba(158, 158, 158, 0.50);
  }

  .EventTitle {
    color: #262626;
    font-family: 'Noto Sans';
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }

  h2 {
    color: #262626;
    font-family: 'Noto Sans';
    font-size: clamp(20px, 2.2vw, 34px);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
  }
  .RegistraionCompltModalHeader {
    padding: 0 !important;
    padding-bottom: 32px !important;
    border-bottom: 1px solid #E0E0E0 !important;
    @media screen and (max-width: 668px){
      padding: 16px !important;
    }
  }
  .RegistraionCompltModalBody {
    padding: 32px 0 0 !important;

    @media screen and (max-width: 668px){
      padding: 16px !important;
    }

    .ModalFooterButtons{
      box-shadow: 0px -6px 30px 0px rgba(0, 0, 0, 0.10);
      background: #FFF;
      padding: 19px 68px;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      max-width: 836px;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      @media screen and (max-width: 668px){
        padding: 16px !important;
        button{
          width: 100%;
        }
      }
    }
  }
  .ItemNumb {
    width: 45px;
    min-width: 45px;
    height: 45px;
    min-height: 45px;
    border-radius: 50%;
    border: 0;
    background: #E5EDF4;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #004E9C;
    font-family: 'Noto Sans';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .EventMapContainer {
    margin-bottom: 32px;
    border-radius: 8px;
    overflow: hidden;
    @media screen and (max-width: 568px) {
      margin-bottom: 16px;
    }
  }
  .DetailsSectionRight {
    padding: 0;

    .ItemTitleRow {
      display: flex;
      align-items: flex-start;
      gap: 16px;
    }

    .DividerFull{
      margin: 32px 0;
    }

    p {
      color: #262626;
      font-family: 'Noto Sans';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;
      margin-top: 16px;
      @media screen and (max-width: 568px) {
        margin-top: 0;
      }
    }

    .PromotionalVideo{
      width: 100%;
      height: 349px;
      border-radius: 12px;
      overflow: hidden;
      margin: 32px 0;
      @media screen and (max-width: 568px){
        height: 193px;
      }
      iframe{
        width: 100%;
        height: 100%;
      }
    }
    .ButtonRow {
      flex-direction: row;
      grid-gap: 0.5rem;

      @media screen and (max-width: 991px) {
        flex-direction: column;
      }

      .LogoCon {
        display: flex;
        align-items: center;
        gap: 12px;

        .MemberAppLogo {
          width: 40%;
          max-width: 57px;
          object-fit: contain;
        }

        h4 {
          color: #00366B;
          font-family: 'Noto Sans';
          font-size: 1.1rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin: 0;
        }
      }
      .DownloadButton{
        display: flex;
        align-items: center;
        justify-content: center;
        //gap: 12px;

        @media screen and (max-width: 568px){
          width: auto !important;
        }
        .BtnIcon{
          width: 16px;
        }
      }
    }
  }

  .EventBtns{
    gap: 12px;
    Button{
      text-transform: none !important;
    }
    @media screen and (max-width: 568px){
      flex-direction: column-reverse;
      position: fixed;
      width: 100%;
      border-top: 1px solid rgba(158, 158, 158, 0.50);
      background: #FFF;
      padding: 16px;
      left: 0;
      bottom: 0;
      button{
        width: 100%;
        padding: 16px 20px !important;
      }
    }
  }

  .Divider{
    width: 100%;
    height: 1px;
    background: #E0E0E0;
    margin: 32px 0;
  }

  .RegCompltModalFormControl{
    width: 100%;
    border-radius: 8px;
    border: 1px solid #E0E0E0;
    background: #FFF;
    max-width: 285px;
    padding: 11px 14px;
    color: #262626;
    font-family: 'Noto Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
  .Icon {
    width: 16px;
  }

  .FormCheckBox {
    width: 24px;
    height: 24px;
  }

  @media screen and (min-width: 568px){
    .ItemTitleRow{
      flex-direction: column;
      align-items: flex-start !important;
    }
    .evenDetailsCon, .DetailsSectionRight{
      justify-content: flex-start !important;
      padding: 16px;
    }
    .DetailsSectionRight{
      overflow-y: auto;
      .ItemOne{
        // min-height: calc(100vh - 250px);
        height: auto;
      }
    }
    .DividerFull{
      display: none;
    }
    .ItemTwo{
      padding-bottom: 60px;
      .LogoCon{
        width: 100%;
        margin-bottom: 24px;
      }
    }
    .ButtonRow{
      flex-direction: column;
      Button{
        width: 100%;
      }
    }
    .ItemTwo{
      .ButtonRow, .LogoCon, .PromotionalVideo{
        position: relative;
        background: #ffffff;
      }
    }
  }
}