.Title {
    font-weight: 600;
    font-size: 2rem;
    font-family: 'Noto Sans';
}

.Paragraph {
    font-size: 1.2rem;
    font-family: 'Noto Sans';
}

.Image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    min-height: 400px;
}

.GeneralImage {
    background-image: url('../../../assets/images/invation-images/general-image.png');
}

.BccImage {
    background-image: url('../../../assets/images/invation-images/bcc.jpg');
}

.ResetButton {
    font-size: 0.9rem;
}

.OuterContainer {
    margin-right: auto;
    margin-left: auto;
}

.InnerContainer {
    margin-left: auto !important;
    margin-right: auto !important;
}

.EventListLeftContainer {
    max-height: calc(100vh - 138px);
    overflow: auto;
    background-color: #ffffff !important;
}

.EventListMobileContainer {
    overflow: auto;
    background-color: #ffffff !important;
}

.EventListRightContainer {
    height: calc(100vh - 140px);
    max-height: max-content;
}

.EventListItem {
    box-shadow: none !important;
    border: 1px #cac4d0 solid !important;
    background-color: #fffbfe !important;
    border-radius: 12px !important;
    font-family: 'Noto Sans';

    img {
        border-top-left-radius: 12px !important;
        border-top-right-radius: 12px !important;
    }
}

.EventListItemLoadingImage {
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.EventListItem_Title {
    font-weight: 700;
    line-height: 1.5;
    font-size: 1.1rem;
}

.EventListItem_Content {
    line-height: 1.5;
    font-size: 0.9rem;
}

.EventListItem_JoinButton {
    font-size: 0.8rem !important;
}

.CustomMapView {
    top: -5.3rem;
    height: calc(100vh - 62px);
}

.CustomMapViewMobile {
    top: -4rem;
    height: calc(100vh - 62px);
}
