.ClientLogo {
    width: 100%;
    height: auto;
    object-fit: contain;
}
.SiteLogoCon {
    img {
        width: 100%;
    }
    @media screen and (max-width: 568px) {
        max-width: 170px;
    }
}
.NavContainer {
    border-bottom: 1px solid #e5e5e5;
    padding: 0 36px 0 42px;
    @media screen and (max-width: 568px) {
        padding: 0 16px;
    }
}
.NavClientLogoCon {
    max-width: 100px;
    @media screen and (max-width: 568px) {
        max-width: 86px;
    }
}
