.ViewDetailsStatus {
  .EventDescription{
    color:#262626;
    font-family: 'Noto Sans';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  .DividerFull {
    height: 1px;
    width: 100%;
    background: rgba(158, 158, 158, 0.50);
  }

  .EventMapContainer{
    border-radius: 12px;
    overflow: hidden;

    .CustomInnerMapClass {
      height: 200px;
    }
  }
}