.ConfirmingStatus {
  height: 100%;

  .EventMapContainer{
    border-radius: 8px;
    overflow: hidden;

    .CustomInnerMapClass {
      height: 200px;
    }
  }

  .DividerFull {
    height: 1px;
    width: 100%;
    background: rgba(158, 158, 158, 0.50);
  }

  .EventTitle {
    color: #262626;
    font-family: 'Noto Sans';
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }
}